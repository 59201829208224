<template>
  <div>PLAY GROUND COMPONENT</div>
</template>

<script>
export default {
  async setup() {
    await utilities.wait(4000);
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
